import React, { useState } from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import ButtonLink from '@components/ui/buttons/ButtonLink'
import { DescriptionDisplay1 } from '@components/ui/typography/Typography'
import { StaticImage } from 'gatsby-plugin-image'
import TagRanked from '@components/common/TagRanked'
import ButtonVideo from '@components/ui/buttons/ButtonVideo'
import { generateAppLink } from '@configs/helpers'
const HeroSectionV8 = ({ intl }) => {
  const [openModal, setOpenModal] = useState(false)

  const openModalHandler = () => {
    !openModal ? setOpenModal(true) : setOpenModal(false)
  }
  return (
    <SectionWrapper sectionBackground={color.blueBackground} showWaves>
      <Wrapper>
        <Text>
          <TagRanked
            text={intl.formatMessage({ id: 'miscellaneous.ratedOne' })}
            margin="0 0 -20px 0"
            backgroundColor={color.blueLight}
          />
          <div className="headingWrapper">
            <HeadingH1>{intl.formatMessage({ id: 'home.hero.title.homepage' })}</HeadingH1>
          </div>
          <div className="descriptionWrapper">
            <DescriptionDisplay1>{intl.formatMessage({ id: 'home.hero.homepage3Description' })}</DescriptionDisplay1>
          </div>
          <div className="buttonLinkWrapper">
            <div className="buttons">
              <ButtonLink
                // to="/pricing"
                external
                href={generateAppLink('signup', intl.locale)}
                buttonBackground={color.blue}
                buttonColor={color.white}
                buttonText={intl.formatMessage({ id: 'buttons.tryForFree' })}
                smfontsize={'16px'}
                mdfontsize={'16px'}
              />
            </div>
            <p>{intl.formatMessage({ id: 'home.hero.try' })}</p>
          </div>
        </Text>
        <SliderWrapper>
          <ImageVideo onClick={openModalHandler}>
            <VideoButtonWrapper>
              <ButtonVideo openModal={openModal} videoId="Mb0-CeTuzY8" buttonColor="red" />
            </VideoButtonWrapper>
            <StaticImage src="../../assets/images/home/hero/heroImage7Ai.webp" alt="Hero image" />
          </ImageVideo>
        </SliderWrapper>
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(HeroSectionV8)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  @media screen and (min-width: ${breakpoint.sm}) {
    align-items: center;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 50px;
    padding-bottom: 100px;
  }
`

const HeadingH1 = styled.h1`
  font-size: 38px;
  font-weight: 700;
  color: ${color.font};

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 48px;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;

  @media screen and (min-width: ${breakpoint.xl}) {
    align-items: flex-start;
    max-width: 45%;
  }

  .headingWrapper {
    text-align: center;

    @media screen and (min-width: ${breakpoint.xl}) {
      text-align: left;
    }
  }

  .descriptionWrapper {
    max-width: 450px;
    text-align: center;

    @media screen and (min-width: ${breakpoint.md}) {
      max-width: 550px;
    }

    @media screen and (min-width: ${breakpoint.xl}) {
      text-align: left;
    }
  }

  .buttonLinkWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 12px;

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 12px;

      @media screen and (min-width: ${breakpoint.xl}) {
        padding-bottom: 0;
        flex-direction: row;
        column-gap: 12px;
        justify-content: flex-start;
      }
    }

    p {
      opacity: 0.4;
      font-size: 12px;
    }
  }
`

const SliderWrapper = styled.div`
  margin: 50px auto 0;
  background-color: #f5f7fb;
  border-radius: 12px;
  border: 8px solid #081e4a;
  width: 100%;
  max-width: 600px;

  @media screen and (min-width: ${breakpoint.md}) {
    border-radius: 18px;
    border: 13px solid #081e4a;
    width: 65%;
  }
`

const ImageVideo = styled.div`
  cursor: pointer;
  position: relative;
  box-shadow: 0 5px 30px rgba(8, 30, 74, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
`

const VideoButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
`
